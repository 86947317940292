<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      spinner-variant="primary"
    >
      <b-card>
        <div class="d-flex  align-items-center justify-content-start">
          <div class="d-flex align-items-baseline ">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="ml-1">
              All Requests
              <b-badge
                variant="primary"
                class="ml-50"
              >
                {{ $route.params.sname }}
              </b-badge>
            </h3>
          </div>
        </div>
        <hr>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-start mt-2">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search requests"
              type="text"
            />
            <!-- <b-button
              v-b-modal.addRequestModal
              variant="primary"
            >
              <feather-icon icon="PlusIcon" />
              Add Partner Service
            </b-button> -->
            <!-- <span class="ml-2 mr-1 font-weight-bold">Show requests raised by</span>
            <b-form-select
              v-model="requester"
              :options="[{value: null, text: 'Startup & Incubator'}, 'Incubator','Startup']"
            /> -->
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="requests"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'title'"
            >
              {{ props.row.users_organizationtable.title }}
            </span>
            <span
              v-else-if="props.column.field === 'status'"
            >
              <b-badge
                style="text-transform: capitalize"
                :variant="`${props.row.status === 'approved' ? 'primary' : (props.row.status === 'unchecked' ? 'light-primary' : 'light-danger')}`"
              >{{ props.row.status }}</b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'action'"
              class="d-flex align-items-center"
            >
              <b-button
                v-if="props.row.status !== 'approved'"
                size="sm"
                variant="primary"
                class="d-flex align-items-center mr-1"
                @click="updateStatus(props.row.id, 'approved')"
              >
                Accept
              </b-button>
              <b-button
                v-if="props.row.status !== 'rejected'"
                size="sm"
                variant="outline-danger"
                @click="updateStatus(props.row.id, 'rejected')"
              >
                Reject
              </b-button>
              <!-- <b-button
                size="sm"
                variant="flat-danger"
              >
                <feather-icon icon="TrashIcon" />
              </b-button> -->
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <b-modal
          id="addRequestModal"
          no-close-on-backdrop
          size="md"
          title="Add Partner Service"
          ok-title="Add"
          @ok="addRequest"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Service Title"
                label-for="Service Title"
              >
                <b-form-input
                  id="title"
                  v-model="title"
                  placeholder="Service Title"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Description"
                label-for="Description"
              >
                <b-form-textarea
                  id="description"
                  v-model="description"
                  placeholder="Description"
                  type="text"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Type"
                label-for="Type"
              >
                <v-select
                  v-model="type"
                  class="text-capitalize"
                  :options="['Free Credits','Discount','Consultation','Free Service/Product','Other']"
                  placeholder="Choose Type"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Instructions"
                label-for="Instructions"
              >
                <b-form-textarea
                  id="instructions"
                  v-model="instructions"
                  placeholder="Instructions"
                  type="text"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div class="d-flex align-items-center">
                <span>Show Instructions On Approval?</span>
                <b-form-checkbox
                  v-model="show_instructions"
                  class="ml-50"
                  inline
                  switch
                ><span class="switch-icon-left">
                   <feather-icon icon="CheckIcon" />
                 </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span></b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </b-modal>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>

import {
  BCard,
  BBadge,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BPagination,
  BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { formatDate } from '@/@core/utils/utils'

export default {
  components: {
    BFormInput,
    BCard,
    BFormTextarea,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
    // Treeselect,
    vSelect,
    BFormCheckbox,
  },
  data() {
    return {
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      title: '',
      description: '',
      instructions: '',
      type: '',
      show_instructions: true,
      columns: [
        {
          label: 'Startup',
          field: 'title',
        },
        {
          label: 'Request Date',
          field(row) {
            return row.created_at ? formatDate(row.created_at) : 'N/A'
          },
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      requester: null,
      requests: [],
      mutationLoading: false,
      request: {
        title: '',
        description: '',
        priority: '',
      },
      assignee: null,
      associations: [
        {
          id: '0',
          label: 'Startups',
          children: [
            {
              id: '0-0',
              label: 'Incubatee',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-1',
              label: 'Graduate',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-2',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-3',
              label: 'Pre-Incubatee',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '1',
          label: 'Students',
          children: [
            {
              id: '1-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '1-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '2',
          label: 'Mentors',
          children: [
            {
              id: '2-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '2-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '3',
          label: 'Partners',
          children: [
            {
              id: '3-0',
              label: 'In-House',
              children: [],
              isDisabled: true,
            },
            {
              id: '3-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        assigned: 'light-secondary',
        unassigned: 'light-warning',
        unchecked: 'light-secondary',
        'in-process': 'light-primary',
        resolved: 'light-success',
        closed: 'light-danger',
        low: 'light-success',
        medium: 'light-info',
        high: 'light-warning',
        urgent: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    // filteredRows() {
    //   if (!this.requester) return this.requests
    //   // if requester is not $route.params.id, it's raised by incubator
    //   return this.requester !== 'Incubator' ? this.requests.filter(r => r.requester === this.$route.params.id) : this.requests.filter(r => r.requester !== this.$route.params.id)
    // },
  },
  methods: {
    updateStatus(id, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestStatus {
            update_partnerservices_request_by_pk(pk_columns: {id: ${id}}, _set: {status: "${status}"}) {
              id
            }
          },
        `,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestAssignee(requestId, assigneeId) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestAssignee($requestId: Int!, $assigneeId: Int!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {assignee_id: $assigneeId, assignee_status: "unchecked"}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          assigneeId,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request assigned successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.assignee = null
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating assignee',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
          this.assignee = null
        })
    },
  },
  apollo: {
    requests: {
      query() {
        return gql`query requests {
          partnerservices_request(where: {partnerservices_id: {_eq: ${this.$route.params.sid}}}) {
            id
            rating
            remarks
            status
            created_at
            users_organizationtable {
              title
            }
          }
        }`
      },
      update: data => data.partnerservices_request,
    },

    // associations: {
    //   query() {
    //     return gql`
    //     query MyQuery {
    //       programs_partnerstable(where: {program_id: {_eq: ${this.$route.params.id}}}) {
    //         id
    //         users_customuser {
    //           full_name
    //         }
    //       }
    //     }`
    //   },
    //   update: data => data.programs_partnerstable,
    // },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table';
</style>
